import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Typography from 'components/atoms/Typography'

import './PublishedAt.scss'

function PublishedAt({ className, created, updated }) {
  return (
    <div className={classnames('published_at section-spacing', className)}>
      <div className="section-title">
        <Typography color="gray-100" cssClass="title">
          Publicado em {created}
          <br />
          Atualizado em {updated}
        </Typography>
      </div>
    </div>
  )
}

PublishedAt.propTypes = {
  className: PropTypes.string,
  created: PropTypes.string,
  updated: PropTypes.string,
}

export default PublishedAt