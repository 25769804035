import React, { useEffect } from 'react'
import get from 'lodash.get'
import ReactHtmlParser from 'react-html-parser'
import NewsBlock from 'components/organisms/NewsBlock'
import dataGa from 'helpers/dataGa'

const Page = (props) => {
  const { data, user, refs } = props
  const entity = get(data, 'route.entity') || { content: [] }
  const title = get(entity, 'title')
  const description = get(entity, 'body.value')

  useEffect(() => {
    dataGa(entity.dataGa)
  }, [])

  return <>{description && <NewsBlock text={ReactHtmlParser(description)} title={title} />}</>
}

export default Page
