import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import PropTypes from 'prop-types'

import Typography from 'components/atoms/Typography'
import Image from 'components/atoms/Image'

import PublishedAt from 'components/molecules/PublishedAt'

import './NewsBlock.scss'

function NewsBlock({ text, image, title, created, updated, hasGallery=false }) {
  return (
    <div className="NewsBlock news section-spacing bg-gray-400">
      <Container>
        <Col>
          <Typography variant="h2" component="2" color="primary" cssClass="news__title text-left title-bold-lg mb-5">
            {title}
          </Typography>
        </Col>
        <Col>
          {image && <Image className="news__image mb-5" src={image.src} alt={image.alt} />}
          <Typography cssClass="news__text" variant="span" color="gray-100">
            {text}
          </Typography>
          
          {!hasGallery && (<PublishedAt created={created} updated={updated} />)}
        </Col>
      </Container>
    </div>
  )
}

NewsBlock.defaultProps = {
  hasGallery: false,
}

NewsBlock.propTypes = {
  image: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string,
  }),
  text: PropTypes.node,
  title: PropTypes.string,
  hasGallery: PropTypes.bool,
}

export default NewsBlock
